import React, { useState } from 'react';
import { Alert, Button, Card, Row, Col, Offcanvas, Form } from 'react-bootstrap';
import { FaSort, FaArrowUp, FaArrowDown } from 'react-icons/fa';

/**
 * Returns a Bootstrap class name that determines the background color
 * of a ChoreCard based on its status.
 * @param {string} status - The status of the ChoreCard.
 * @returns {string} A Bootstrap class name.
 */
const getStatusBgClass = (status) => {
    switch (status) {
        case 'new':
            return 'bg-primary text-white';
        case 'pending':
            return 'bg-warning text-dark';
        case 'in review':
            return 'bg-info text-white';
        case 'completed':
            return 'bg-success text-white';
        case 'rejected':
            return 'bg-danger text-white';
        default:
            return 'bg-secondary text-white';
    }
};

/**
 * A component that displays a list of chores. The list of chores can be sorted by the user.
 * The component also displays a list of chores that are in review for the parent user.
 * The component is used in the child dashboard and the admin dashboard.
 * @param {Object} chores The list of chores to be displayed.
 * @param {Function} onEditChore The function to be called when a chore is tapped.
 * @param {Function} onAddChore The function to be called when the user wants to add a chore.
 * @param {Function} handleReviewChore The function to be called when the user wants to review a chore.
 * @param {Array} reviewChores The list of chores to be reviewed.
 * @param {boolean} isAdmin Whether the user is an admin or not.
 * @returns {JSX.Element} The component to be rendered.
 */
const ChoreList = ({ chores, onEditChore, onAddChore, handleReviewChore, reviewChores, isAdmin  }) => {
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [sortCriteria, setSortCriteria] = useState('points');
    const [sortOrder, setSortOrder] = useState('asc');  
    // eslint-disable-next-line 
    const [error, setError] = useState(null);

    const dayOrder = { 'Sunday': 0, 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Any': 7 };

    const sortedChores = [...chores].sort((a, b) => {
        if (sortCriteria === 'dueDay') {
            return sortOrder === 'asc' 
                ? dayOrder[a.dueDay] - dayOrder[b.dueDay] 
                : dayOrder[b.dueDay] - dayOrder[a.dueDay];
        } else {
            let comparison = 0;
            if (a[sortCriteria] > b[sortCriteria]) {
                comparison = 1;
            } else if (a[sortCriteria] < b[sortCriteria]) {
                comparison = -1;
            }
            return sortOrder === 'asc' ? comparison : -comparison;
        }
    });

    /**
     * Toggles the sort order of the chores list between ascending and descending.
     */
    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div className="mt-5">
            {isAdmin && (
                <div className="mb-5">
                <h2 className="mt-5">Chore Review</h2>
                    {reviewChores.length > 0 ? (
                        <Row>
                        {reviewChores.map(chore => (
                            <Col key={chore._id} sm={12} md={6} lg={4} className="mb-3">
                                <Card className="bg-info text-white" style={{ cursor: 'pointer' }} onClick={() => handleReviewChore(chore)}>
                                    <Card.Header as="h6" className="d-flex justify-content-between">
                                        <span>{chore.assignedToName}</span>
                                        <strong className="ml-auto">{chore.status}</strong>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>{chore.description}</Card.Title>
                                    </Card.Body>
                                    <Card.Footer className="text-center p-0">
                                        <small className="text-white">Tap to Review</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    ) : (
                        <>
                            <hr className="mt-0" />
                            <Alert variant="light">
                                No chores available for review.
                            </Alert>
                        </>
                    )}
                </div>
            )}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Chore List</h2>
                <div className="d-flex justify-content-between align-items-center">
                    <Button 
                        variant="none" 
                        className="mr-auto"
                        aria-label="Sort By" 
                        onClick={() => setShowSortOptions(true)}
                    >
                        <FaSort />
                    </Button>
                    <Button 
                        variant="primary"
                        aria-label="Add Chore"
                        onClick={onAddChore}
                    >
                        Add Chore
                    </Button>
                </div>
            </div>
            {sortedChores.length > 0 ? (
                <Row>
                    {sortedChores.map((chore) => (
                        <Col key={chore._id} sm={12} md={6} lg={4} className="mb-3">
                            <Card className={`${getStatusBgClass(chore.status)} card-hover`} onClick={() => onEditChore(chore)} style={{ cursor: 'pointer' }}>
                                <Card.Header as="h6" className="d-flex justify-content-between">
                                    <span>{chore.assignedToName}</span>
                                    <strong className="ml-auto">{chore.status}</strong>
                                </Card.Header>
                                <Card.Body className="pt-2">
                                    <Row>
                                        <Col xs={10}>
                                            <Card.Title>{chore.description}</Card.Title>                                   
                                            <Card.Text>
                                                <small>Due: {chore.dueDay}</small>
                                            </Card.Text>
                                        </Col>
                                        <Col xs={2} className="text-center d-flex flex-column justify-content-center p-0" style={{ borderLeft: 'var(--bs-border-width) solid var(--bs-border-color-translucent)' }}>
                                            <Card.Text as="div">
                                                <small>Points</small>
                                                <br />
                                                <h4>{chore.points}</h4>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-center p-0">
                                    <small className="">Tap to Edit</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                <>
                    <hr className="mt-0" />
                    <Alert variant="light">
                        No chores available.
                    </Alert>
                </>
            )}

            <Offcanvas show={showSortOptions} onHide={() => setShowSortOptions(false)} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Sort Options</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group controlId="sortCriteria" className="d-flex align-items-center">
                            <Form.Control as="select" value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)}>
                                <option value="points">Point Value</option>
                                <option value="status">Status</option>
                                <option value="assignedToName">Assigned To</option>
                                <option value="description">Chore Description</option>
                                <option value="dueDay">Due Day</option>
                            </Form.Control>
                            <Button 
                                variant="none" 
                                className="ml-2" 
                                aria-label= {sortOrder === 'asc' ? 'Sort Ascending' : 'Sort Descending'}
                                onClick={toggleSortOrder}
                            >
                                {sortOrder === 'asc' ? <FaArrowUp /> : <FaArrowDown />}
                            </Button>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default ChoreList;