import axios from 'axios';
import getUsers from './userService';

const API_URL = '/api/chores/';

const createChore = (choreData) => {
    return axios.post(API_URL, choreData);
};

const getChores = () => {
    return axios.get(API_URL);
};

const updateChore = (id, choreData) => {
    return axios.put(`${API_URL}${id}`, choreData);
};

const deleteChore = (id) => {
    return axios.delete(`${API_URL}${id}`);
};

const completeChore = (id) => {
    return axios.post(`${API_URL}${id}/complete`);
};

const reviewChore = (choreId, status, rejectionExplanation = '') => {
    return axios.post(`${API_URL}review`, { choreId, status, rejectionExplanation });
};

const uploadImage = (formData, onUploadProgress) => {
    return axios.post('/api/upload/upload', formData, { 
        onUploadProgress,
    });
};

export const fetchChores = async () => {
    try {
        const resGetChores = await getChores();
        const sortedChores = resGetChores.data.sort((a, b) => b.points - a.points);
        //console.log('choreService.js fetchChores(): Fetching chores...');
        return sortedChores;
    } catch (err) {
        console.error('Error fetching chores:', err);
        throw err;
    }
};

export const fetchUsers = async () => {
    try {
        const resGetUsers = await getUsers();
        return resGetUsers.data;
    } catch (err) {
        console.error('Error fetching users:', err);
        throw err;
    }
};

export const mergeUserNamesIntoChores = (chores, users) => {
    return chores.map(chore => {
        const assignedToId = chore.assignedTo && typeof chore.assignedTo === 'object' ? chore.assignedTo._id : chore.assignedTo;
        const user = users.find(user => user._id === assignedToId);
        return {
            ...chore,
            assignedToName: user ? user.name : null,
        };
    });
};


// Favorite Chore Services
const getFavoriteChores = () => axios.get(`${API_URL}favorites`);
const addFavoriteChore = (favoriteData) => axios.post(`${API_URL}favorites`, favoriteData);
const removeFavoriteChore = (favoriteId) => axios.delete(`${API_URL}favorites/${favoriteId}`);
const copyFavoriteChore = (favoriteId) => axios.post(`${API_URL}${favoriteId}/copy`);
const toggleFavorite = (choreId) => axios.post(`${API_URL}${choreId}/favorite`);
  
const choreService = {
    createChore,
    getChores,
    updateChore,
    deleteChore,
    completeChore,
    reviewChore,
    uploadImage,
    toggleFavorite,
    copyFavoriteChore,
    getFavoriteChores,
    addFavoriteChore,
    removeFavoriteChore,
};

export default choreService;
