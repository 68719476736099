import React, { useState } from 'react';
import { Button, Form, ProgressBar, InputGroup } from 'react-bootstrap';
import imageCompression from 'browser-image-compression';

const ImageUpload = ({ choreId, onUploadSuccess, onError }) => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    // eslint-disable-next-line 
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    
    const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file.type.startsWith('video/')) {
        console.log('Video file selected:', file);
        setImage(file);
    } else if (file.type.startsWith('image/')) {
        // Compression options
        const options = {
        maxSizeMB: 0.20,
        maxWidthOrHeight: 700, 
        useWebWorker: true,
        };

        try {
        const compressedFile = await imageCompression(file, options);

        // Manually set the name of the compressed file
        const newFileName = `image-${Date.now()}.${compressedFile.type.split('/')[1]}`;
        const compressedFileWithNewName = new File([compressedFile], newFileName, {
            type: compressedFile.type,
            lastModified: Date.now(),
        });

        setImage(compressedFileWithNewName);
        } catch (err) {
        setError('Error compressing the image');
        console.error('Error compressing the image:', err);
        }
    } else {
        setError('Error: Only images and videos are allowed');
    }
    };

    /**
     * Handles the submission of the image upload form
     * @param {Event} e The form submission event
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!image) {
            setError('No image selected or image compression failed');
            return;
        }

        const formData = new FormData();
        formData.append('image', image);
        formData.append('choreId', choreId);

        console.log('FormData contents:');
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        setIsUploading(true);

        try {
            const res = await fetch('/api/upload/upload', {
                method: 'POST',
                body: formData,
            });

            if (!res.ok) {
                throw new Error('Failed to upload image');
            }

            const data = await res.json();
            setIsUploading(false);
            onUploadSuccess && onUploadSuccess(data.imageUrl);
        } catch (err) {
            setError(err.message);
            setIsUploading(false);
            onError && onError(err.message);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <div className="alert alert-danger">{error}</div>} 
            <Form.Label>Upload Image</Form.Label>   
            <InputGroup className="mb-3">
                <Form.Control type="file" onChange={handleFileChange} required />
                <Button 
                    type="submit" 
                    disabled={!image || isUploading}
                    aria-label="Upload"
                >
                    Upload
                </Button>
            </InputGroup>
            {isUploading && 
                <ProgressBar 
                    now={uploadProgress} 
                    label={`${uploadProgress}%`}
                    aria-label="Completion progress"  
                />
            }
        </Form>
    );
};

export default ImageUpload;
