// frontend/src/components/Navbar.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Button, Offcanvas, Container, Modal } from 'react-bootstrap';
import { FaBars, FaUserPlus, FaUndo, FaSignOutAlt, FaSync } from 'react-icons/fa';
import axios from 'axios';

const CHNavbar = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLogoClick = () => {
        localStorage.removeItem('userId');
        navigate('/login');
    };

    const handleSignupClick = () => {
        navigate('/signup', { state: { fromAdmin: true } });
    };

    const handleReset = async () => {
        try {
            await axios.delete('/api/reset/reset');
            window.location.reload();
        } catch (error) {
            alert('Error resetting chores and user points');
        }
        setShowConfirmation(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('userId');
        navigate('/login');
    };

    const handleSync = () => {
        window.location.reload();
    };

    return (
        <Navbar>
            <Container fluid style={{ height: '66px' }}>
                <Navbar.Brand href="/" onClick={(e) => { e.preventDefault(); handleLogoClick(); }}>
                    <img
                        alt="Logo"
                        src={require('../assets/images/logo-text-90x60.webp')}
                        width="90"
                        height="60"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Brand><h1>{title}</h1></Navbar.Brand>

                {location.pathname === '/child' && (
                    <Button 
                        variant="outline-primary" 
                        onClick={handleSync}
                        aria-label="Sync"
                    >
                        <FaSync />
                    </Button>
                )}
                {location.pathname === '/admin' && (
                    <>
                        <Button 
                            variant="outline-primary" 
                            onClick={handleShow}
                            aria-label="Expand Navigation"
                        >
                            <FaBars />
                        </Button>
                        <Offcanvas show={show} onHide={handleClose} placement="end">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Admin Menu</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="d-flex flex-column justify-content-between">
                                <div>
                                    <Nav className="flex-column">
                                        <Nav.Link onClick={handleSync}>
                                            <FaSync style={{ marginRight: '8px' }} />
                                            Force Sync 
                                        </Nav.Link>
                                        <Nav.Link onClick={handleSignupClick}>
                                            <FaUserPlus style={{ marginRight: '8px' }} />
                                            Register New User 
                                        </Nav.Link>
                                    </Nav>
                                </div>

                                {/* Bottom Section */}
                                <div>
                                <hr />
                                    <Nav className="flex-column">
                                        <Nav.Link onClick={() => setShowConfirmation(true)} className="text-danger">
                                            <FaUndo style={{ marginRight: '8px' }} />
                                            Reset Chores & Points
                                        </Nav.Link>
                                        <Nav.Link onClick={handleLogout}>
                                            <FaSignOutAlt style={{ marginRight: '8px' }} />
                                            Logout
                                        </Nav.Link>
                                    </Nav>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                )}
            </Container>

            {/* Reset Confirmation Modal */}
            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to reset all chores and user points? This action cannot be undone. <br></br><br></br><small>This will <b>NOT</b> delete Favorite Chores.</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={() => setShowConfirmation(false)}
                        aria-label="Cancel Reset"
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="danger" 
                        onClick={handleReset}
                        aria-label="Confirm Reset"
                    >
                        Yes, Reset
                    </Button>
                </Modal.Footer>
            </Modal>
        </Navbar>
    );
};

export default CHNavbar;
