// frontend/src/components/FavoriteChoreList.js
import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import choreService from '../services/choreService';
import { FaTrash, FaClone } from 'react-icons/fa';

const FavoriteChoreList = ({ favorites, onChoreCreated }) => {
  const [favoriteChores, setFavoriteChores] = useState([]);
  const [error, setError] = useState(null);
  const renderTooltipDeleteFavorite = (props) => (
  <Tooltip {...props}>Delete Favorite</Tooltip>
  );
  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const resGetFavoriteChores = await choreService.getFavoriteChores();
        setFavoriteChores(resGetFavoriteChores.data);
      } catch (err) {
        console.error('Error fetching favorite chores:', err);
        setError('Failed to load favorite chores.');
      }
    };

    fetchFavorites();
  }, []);

  const handleRemoveFavorite = async (favoriteId) => {
    try {
      await choreService.removeFavoriteChore(favoriteId);
      // Notify via WebSocket if applicable
      const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');

      ws.onopen = () => {
          ws.send(JSON.stringify({ type: 'FAVORITE_DELETED' }));
          ws.close();
      };
      
      setFavoriteChores(favoriteChores.filter(fav => fav._id !== favoriteId));
    } catch (err) {
      console.error('Error removing favorite chore:', err);
      setError('Failed to remove favorite chore.');
    }
  };

  const handleUseAsTemplate = async (favoriteId) => {
    try {
      const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');

      ws.onopen = () => {
          ws.send(JSON.stringify({ type: 'CHORE_CREATED_FROM_FAVORITE' }));
          ws.close();
      };
      const resCopyFavoriteChore = await choreService.copyFavoriteChore(favoriteId);
      onChoreCreated(resCopyFavoriteChore.data);
    } catch (err) {
      console.error('Error using favorite chore as template:', err);
      setError('Failed to create chore from favorite.');
    }
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="mt-5">
      <h2 className="mb-3">Favorite Chores</h2>
      <Row>
        {favoriteChores.length === 0 && <p>No favorite chores available.</p>}
        {favoriteChores.map(fav => (
          <Col key={fav._id} sm={12} md={6} lg={4} className="mb-3">
            <Card>
              <Card.Body className="pt-2">
                  <Row>
                      <Col xs={10}>
                          <Card.Title>{fav.customName || fav.description}</Card.Title>                                   
                          <Card.Text>
                              <small>Due: {fav.dueDay}</small>
                          </Card.Text>
                      </Col>
                      <Col xs={2} className="text-center d-flex flex-column justify-content-center p-0" style={{ borderLeft: 'var(--bs-border-width) solid var(--bs-border-color-translucent)' }}>
                          <Card.Text as="div">
                              <small>Points</small>
                              <br />
                              <h4>{fav.points}</h4>
                          </Card.Text>
                      </Col>
                  </Row>
              </Card.Body>
              <Card.Footer className="p-1">
                <Row>
                  <Col xs={2}>
                    <OverlayTrigger placement="top" overlay={renderTooltipDeleteFavorite}>
                      <Button 
                        variant="danger" 
                        aria-label="Delete Favorite"
                        onClick={() => handleRemoveFavorite(fav._id)}
                      >
                        <FaTrash /> 
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} className="d-flex justify-content-end">
                    <Button 
                      variant="primary" 
                      aria-label="Create this Chore"
                      onClick={() => handleUseAsTemplate(fav._id)}
                    >
                      <FaClone /> Create this Chore
                    </Button>
                  </Col>
                </Row>
               </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FavoriteChoreList;