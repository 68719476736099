import React, { useState, useEffect, useCallback } from 'react';
import { Container, Button, Modal, Alert, Form, Row, Col, Offcanvas, Badge, Card, Stack, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { FaSort, FaArrowUp, FaArrowDown, FaRegImage } from 'react-icons/fa';
import Draft from '../components/Draft';
import draftService from '../services/draftService';
import choreService from '../services/choreService';
import Rewards from '../components/Rewards';
import CHNavbar from '../components/Navbar';
import ImageUpload from '../components/ImageUpload';
import debounce from 'lodash/debounce';

const ChildDashboard = () => {
    const [draftChores, setDraftChores] = useState([]);
    const [userChores, setUserChores] = useState([]);
    const [error, setError] = useState(null);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showExplanationModal, setShowExplanationModal] = useState(false);
    const [rejectionExplanation, setRejectionExplanation] = useState('');
    const [selectedChore, setSelectedChore] = useState(null);
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [sortCriteria, setSortCriteria] = useState('points');
    const [sortOrder, setSortOrder] = useState('asc');
    const [showImageModal, setShowImageModal] = useState(false);
    const [modalImagePath, setModalImagePath] = useState('');
    const [imageLoading, setImageLoading] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [dueDay, setDueDay] = useState('any');
    const [choreDescription, setChoreDescription] = useState('');

    const userId = localStorage.getItem('userId');// eslint-disable-next-line
    const currentDay = new Date().toLocaleString('en-us', { weekday: 'long' });
    const dayOrder = { 'Sunday': 0, 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6 };


    const fetchChores = useCallback(async () => {
        try {
            const draftRes = await draftService.getDraftChores();
            const sortedDraftChores = draftRes.data.sort((a, b) => b.points - a.points);
            setDraftChores(sortedDraftChores);

            const userRes = await draftService.getUserChores(userId);
            setUserChores(userRes.data);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to fetch data');
        }
    }, [userId]);

    const connectWebSocket = () => {
        const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
        const debounceFetchChores = debounce(fetchChores, 300); // Example with 300ms delay
    
        ws.onopen = () => {
            //console.log('WebSocket connection established');
        };
    
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            //console.log('WebSocket message received:', message);
            if (['CHORE_DELETED', 'NEW_CHORE_ADDED', 'CHORE_SELECTED', 'CHORE_REVIEW', 'CHORE_COMPLETED', 'CHORE_UPDATED'].includes(message.type)) {
                debounceFetchChores(); 
            }
        };
    
        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            setTimeout(() => connectWebSocket(), 5000); // Attempt to reconnect after 5 seconds
        };
    
        ws.onclose = () => {
            console.log('WebSocket connection closed');
            setTimeout(() => connectWebSocket(), 5000); // Attempt to reconnect after 5 seconds
        };
    
        return ws;
    };
    
    useEffect(() => {
        console.log('useEffect triggered, fetching chores');
        fetchChores();
        const ws = connectWebSocket();
        return () => {
            ws.close();
        };// eslint-disable-next-line
    }, []);
    
       const handleSelectChore = async (choreId) => {
        try {
            const res = await draftService.selectChore(userId, choreId);
            setDraftChores(draftChores.filter(chore => chore._id !== choreId));
            setUserChores([...userChores, res.data]);
            
            // Notify the admin dashboard about the selected chore
            const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'CHORE_SELECTED', choreId }));
                ws.close();
            };
        } catch (err) {
            console.error('Error selecting chore:', err);
            setError('Failed to select chore');
        }
    };

    const handleInReview = (chore) => {
        setSelectedChore(chore);
        setShowReviewModal(true);
    };

    const handleShowExplanation = (chore) => {
        setSelectedChore(chore);
        setRejectionExplanation(chore.rejectionExplanation || 'No explanation provided.');
        setShowExplanationModal(true);
    };

    const handleImageUploadSuccess = async () => {
        try {
            await choreService.reviewChore(selectedChore._id, 'in review');
            await fetchChores();

            setShowReviewModal(false);
            setShowExplanationModal(false);
            setSelectedChore(null);
            
            // Notify the admin dashboard about the selected chore
            const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'CHORE_REVIEW' }));
                ws.close();
            };
        } catch (err) {
            console.error('Error submitting review:', err);
            setError('Failed to submit review');
        }
    };

    const handleImageIconClick = (imagePath) => {
        setModalImagePath(imagePath);
        setShowImageModal(true);
    };

    const handleImageLoad = (choreId) => {
        setImageLoading(prev => ({ ...prev, [choreId]: false }));
    };

    const handleImageError = (choreId) => {
        setImageLoading(prev => ({ ...prev, [choreId]: false }));
    };

    const sortedChores = [...userChores].sort((a, b) => {
        let comparison = 0;
        if (a[sortCriteria] > b[sortCriteria]) {
            comparison = 1;
        } else if (a[sortCriteria] < b[sortCriteria]) {
            comparison = -1;
        }
        return sortOrder === 'asc' ? comparison : -comparison;
    });

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const getStatusBgClass = (status) => {
        switch (status) {
            case 'new':
                return 'bg-primary text-white';
            case 'pending':
                return 'bg-warning text-dark';
            case 'in review':
                return 'bg-info text-white';
            case 'completed':
                return 'bg-success text-white';
            case 'rejected':
                return 'bg-danger text-white';
            default:
                return 'bg-secondary text-white';
        }
    };

    const handleCardClick = (chore) => {
        const currentDayNumber = new Date().getDay();
        const choreDueDayNumber = dayOrder[chore.dueDay];
    
        // Allow submission from the due day up to and including Friday (assuming chores cannot be submitted on weekends)
        if (currentDayNumber < choreDueDayNumber || currentDayNumber > dayOrder['Friday']) {
            setDueDay(chore.dueDay);
            setChoreDescription(chore.description);
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000); // hide the toast after 5 seconds
            return;
        }
        switch (chore.status) {
            case 'pending':
                handleInReview(chore);
                break;
            case 'rejected':
                handleShowExplanation(chore);
                break;
            case 'in review':
                break;
            default:
                break;
        }
    };

    const getRandomMessage = () => {
        const messages = ['Great job!', 'Well done!', 'Kudos!', 'Cheers Mate!', 'Fantastic!'];
        return messages[Math.floor(Math.random() * messages.length)];
    };

    return (
        <Container className="mb-5 c-dash">
            <CHNavbar title="My Dashboard" />
            {error && <Alert variant="danger">{error}</Alert>}
            <Rewards />
            <Draft userId={userId} chores={draftChores} onSelectChore={handleSelectChore} />
            <div className="mt-5">
                <Stack direction="horizontal" gap={3}>
                    <div className="p-2">
                        <h2>My Chores</h2>
                    </div>
                    <div className="ms-auto p-2">
                        <Button variant="none" className="p-0 m-0" onClick={() => setShowSortOptions(true)}>
                            <FaSort />
                        </Button>
                    </div>
                    <div className="vr" />
                    <div className="p-2">
                        <h2>
                            {sortedChores.length >= 0 && (
                                <Badge pill bg="info">
                                    {sortedChores.length}
                                </Badge>
                            )}
                        </h2>
                    </div>
                </Stack>
                {sortedChores.length > 0 ? (
                    <Row>
                        {sortedChores.map(chore => chore && chore._id && (
                            <Col key={chore._id} sm={12} md={6} lg={4} className="mb-3">
                                <Card
                                    className={getStatusBgClass(chore.status)}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCardClick(chore)}
                                >
                                    <Card.Header as="h6" className="d-flex justify-content-end">
                                        <span>{chore.status}</span>
                                    </Card.Header>
                                    <Card.Body className="pt-2">
                                        <Row>
                                            <Col xs={10}>
                                                <Card.Title>{chore.description}</Card.Title>
                                            </Col>
                                            <Col xs={2} className="text-center d-flex flex-column justify-content-center p-0" style={{ borderLeft: 'var(--bs-border-width) solid var(--bs-border-color-translucent)' }}>
                                                <Card.Text as="div">
                                                    <span>Points</span>
                                                    <br />
                                                    <h4>{chore.points}</h4>
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {chore.imagePath && (
                                                <>
                                                    {chore.status === 'in review' ? (
                                                        <>
                                                            <div
                                                                className={imageLoading[chore._id] === false ? 'image-hidden' : 'image-loading'}
                                                            >
                                                                <Spinner animation="grow" />
                                                            </div>
                                                            {chore.imagePath && (
                                                                chore.imagePath.endsWith('.mp4') || 
                                                                chore.imagePath.endsWith('.mov') || 
                                                                chore.imagePath.endsWith('.MOV') ? (
                                                                    <video
                                                                        src={chore.imagePath}
                                                                        alt="Chore Evidence"
                                                                        className={imageLoading[chore._id] === false ? 'image-loaded' : 'image-hidden'}
                                                                        controls
                                                                        autoPlay
                                                                        onLoadedData={() => handleImageLoad(chore._id)} // Use onLoadedData for videos instead of onLoad
                                                                        onError={() => handleImageError(chore._id)}
                                                                    >
                                                                        <source
                                                                            src={chore.imagePath}
                                                                            type={chore.imagePath.endsWith('.mp4') ? 'video/mp4' : 'video/quicktime'}
                                                                            className={imageLoading[chore._id] === false ? 'image-loaded' : 'image-hidden'}
                                                                            style={{ display: imageLoading[chore._id] ? 'none' : 'block' }}
                                                                        />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                ) : (
                                                                    <img
                                                                        src={chore.imagePath}
                                                                        alt="Chore Evidence"
                                                                        className={imageLoading[chore._id] === false ? 'image-loaded' : 'image-hidden'}
                                                                        style={{ display: imageLoading[chore._id] ? 'none' : 'block' }}
                                                                        onLoad={() => handleImageLoad(chore._id)}
                                                                        onError={() => handleImageError(chore._id)}
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div
                                                            className="image-icon-container"
                                                            onClick={() => handleImageIconClick(chore.imagePath)}
                                                        >
                                                            <FaRegImage className="image-icon" />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Row>
                                    </Card.Body>
                                    {chore.status === 'completed' && (
                                        <div className='completed-sticker'>
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                viewBox="-10 -10 60 60"
                                            >   
                                                <defs>
                                                    <filter id="dropshadow" height="60px">
                                                        <feGaussianBlur in="SourceAlpha" stdDeviation="1"/>
                                                        <feOffset dx="-2" dy="2" result="offsetblur"/>
                                                        <feMerge> 
                                                            <feMergeNode/>
                                                            <feMergeNode in="SourceGraphic"/> 
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <path 
                                                    fill="#FFFFFF" 
                                                    filter="url(#dropshadow)"
                                                    d="M10.5,40C8.4,40,7,38.7,7,36.6v-0.2c0-1.3,0.9-2.5,2.1-3.1c0.4-2,2.4-3.3,4.1-3.3h0.8c0.4-1,0.8-2.3,1-3.9
                                                    c-0.6-0.6-1.2-1.3-1.8-2.1H8.1c-2.8,0-5.4-2.1-6-4.6c-0.1-0.6-1.7-9-1.9-10c-0.4-2.1,0-4.1,1.2-5.5C2.3,2.6,3.7,2,5.2,2h3.3
                                                    c0.8-1.2,2.1-1.7,3.7-1.7c0.6,0,1.9,0,3.3,0c1.7,0,3.6,0,4.7,0l7,0c1.1,0,3.3,0,4.6,1.8H35c1.5,0,2.8,0.7,3.8,1.8
                                                    c1.2,1.4,1.6,3.5,1.2,5.6c-0.4,2.2-1.6,9.5-1.8,10.1C37.6,22,34.9,24,32.2,24h-5.1c-0.7,0.9-1.4,1.7-2.1,2.3c0.2,1.5,0.6,2.7,1,3.7
                                                    h1.2c1.9,0,3.5,1.4,3.8,3.2c1.2,0.5,2.1,1.7,2.1,3.2l0,0.4c0,0.5,0,1.5-0.8,2.3C31.4,40,30.2,40,29.8,40H10.5z"
                                                />
                                                <path 
                                                    fill="#FFAC33" 
                                                    d="M7.2,7.1h6c1.1,0,1.9-0.1,1.9,1v-2c0-1.1-0.8-2-1.9-2h-8c-2,0-3.6,2.1-3,5c0,0,1.8,9.4,1.9,10
                                                    c0.3,1.5,2.1,3,4,3h7c1.1,0,0-1.9,0-3v-2c0,1.1-0.8,2-1.9,2h-4c-1.1,0-1.8-1-2-2S5.6,9.7,5.6,9.7C5.3,7.8,6.1,7.1,7.2,7.1z M33,7.1
                                                    h-6c-1.1,0-2.9-0.1-2.9,1v-2c0-1.1,1.8-2,2.9-2h8c2,0,3.6,2.1,3,5c0,0-1.6,9.4-1.8,10c-0.4,1.5-2,3-4,3h-7.1c-1.1,0-1-1.9-1-3v-2
                                                    c0,1.1,1.8,2,2.9,2h4c1.1,0,1.8-1,2-2s1.6-7.3,1.6-7.3C34.9,7.8,34.1,7.1,33,7.1z M22.9,24.1c0-7-2.7,0-2.7,0s-3-7-3,0
                                                    s-3.3,10-3.3,10h12.3C26.2,34.1,22.9,31,22.9,24.1z"
                                                />
                                                <path 
                                                    fill="#FFCC4D" 
                                                    d="M31.1,8.6c0,6.8-6.8,18.2-11,18.2c-4.2,0-11-11.4-11-18.2c0-5.2,1-6.3,3-6.3c1.4,0,6,0,8,0l7,0
                                                    C30,2.3,31.1,3,31.1,8.6z"
                                                />
                                                <path 
                                                    fill="#C1694F" 
                                                    d="M29.1,35.1c0,1.1,0.2,2-0.9,2h-16c-1.1,0-1.1-0.9-1.1-2v-1c0-1.1,1.2-2,2.2-2h13.9c1,0,1.9,0.9,1.9,2
                                                    C29.1,34.1,29.1,35.1,29.1,35.1z"
                                                />
                                                <path 
                                                    fill="#C1694F" 
                                                    d="M31.1,36.7c0,0.8,0.2,1.4-1.3,1.4H10.6c-1.3,0-1.5-0.6-1.5-1.4v-0.2c0-0.8,0.7-1.4,1.5-1.4h19.2
                                                    c0.8,0,1.3,0.6,1.3,1.4C31.1,36.4,31.1,36.7,31.1,36.7z"
                                                />
                                            </svg>
                                        </div>

                                    )}
                                    <Card.Footer className="text-center p-0">
                                        <small>
                                            {chore.status === 'completed' ? getRandomMessage() 
                                                : chore.status === 'rejected' ? 'Tap to Review' 
                                                : chore.status === 'in review' ? 'In Review Process' 
                                                : new Date().toLocaleString('en-us', { weekday: 'long' }) === chore.dueDay ? 'Available to Submit' 
                                                : chore.dueDay !== 'Any' ? `Start day restricted to ${chore.dueDay}` : 'Tap to Submit'}
                                        </small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <>
                        <hr className="mt-0" />
                        <Alert variant="light">
                            No available chores remaining.
                        </Alert>
                    </>
                )}
            </div>
            <Offcanvas show={showSortOptions} onHide={() => setShowSortOptions(false)} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Sort Options</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Row>
                            <Col xs={9}>
                                <Form.Group controlId="sortCriteria">
                                    <Form.Control as="select" value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)}>
                                        <option value="points">Point Value</option>
                                        <option value="status">Status</option>
                                        <option value="description">Chore Description</option>
                                        <option value="dueDay">Due Day</option> 
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={3} className="d-flex align-items-center">
                                <Button variant="none" onClick={toggleSortOrder}>
                                    {sortOrder === 'asc' ? <FaArrowUp /> : <FaArrowDown />}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Chore for Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageUpload 
                        choreId={selectedChore?._id}
                        onUploadSuccess={handleImageUploadSuccess}
                        onError={(errMsg) => setError(errMsg)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReviewModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showExplanationModal} onHide={() => setShowExplanationModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Rejection Explanation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedChore && (
                        <>
                            <div className="modal-image-container">
                                <div
                                    className="modal-image-spinner"
                                    style={{
                                        display: imageLoading[selectedChore._id] === false ? 'none' : 'flex',
                                    }}
                                >
                                    <Spinner animation="grow" />
                                </div>
                                {selectedChore.imagePath && (
                                    selectedChore.imagePath.endsWith('.mp4') || 
                                    selectedChore.imagePath.endsWith('.mov') || 
                                    selectedChore.imagePath.endsWith('.MOV') ? (
                                        <video
                                            src={selectedChore.imagePath}
                                            alt="Chore Evidence"
                                            className="modal-image mb-3"
                                            controls
                                            autoPlay
                                            style={{
                                                display: imageLoading[selectedChore._id] ? 'none' : 'block',
                                            }}
                                            onLoadedData={() => handleImageLoad(selectedChore._id)}
                                            onError={() => handleImageError(selectedChore._id)}
                                        >
                                            <source
                                                src={selectedChore.imagePath}
                                                type={selectedChore.imagePath.endsWith('.mp4') ? 'video/mp4' : 'video/quicktime'}
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img
                                            src={selectedChore.imagePath}
                                            alt="Chore Evidence"
                                            className="modal-image mb-3"
                                            style={{
                                                display: imageLoading[selectedChore._id] ? 'none' : 'block',
                                            }}
                                            onLoad={() => handleImageLoad(selectedChore._id)}
                                            onError={() => handleImageError(selectedChore._id)}
                                        />
                                    )
                                )}
                            </div>
                            <Alert variant="warning">{rejectionExplanation}</Alert>
                            <ImageUpload 
                                choreId={selectedChore?._id}
                                onUploadSuccess={handleImageUploadSuccess}
                                onError={(errMsg) => setError(errMsg)}
                            />
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowExplanationModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Chore Evidence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-container">
                        <div
                            className="modal-image-spinner"
                            style={{ display: imageLoading ? 'flex' : 'none' }}
                        >
                            <Spinner animation="grow" />
                        </div>
                        {modalImagePath && (
                            modalImagePath.endsWith('.mp4') || 
                            modalImagePath.endsWith('.mov') || 
                            modalImagePath.endsWith('.MOV') ? (
                                <video
                                    src={modalImagePath}
                                    alt="Chore Evidence"
                                    className="modal-image"
                                    controls
                                    autoPlay
                                    onLoadedData={() => setImageLoading(false)} // Use onLoadedData for videos instead of onLoad
                                    onError={() => setImageLoading(false)}
                                >
                                    <source
                                        src={modalImagePath}
                                        type={modalImagePath.endsWith('.mp4') ? 'video/mp4' : 'video/quicktime'}
                                        className="modal-image"
                                        style={{ display: imageLoading ? 'none' : 'block' }}
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                            <img 
                                src={modalImagePath} 
                                alt="Chore Evidence" 
                                className="modal-image"
                                style={{ display: imageLoading ? 'none' : 'block' }}
                                onLoad={() => setImageLoading(false)}
                                onError={() => setImageLoading(false)}
                            />
                            )
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowImageModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast style={{ position: 'fixed', bottom: 5, right: 0, width: '100%' }} bg="danger" onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
                    <Toast.Header style={{ backgroundColor: 'rgba(33, 37, 41, 0.15)' }}  className='text-white'>
                        <strong className="m-auto"> {choreDescription}</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white text-center'>
                        Chore can be submitted on {dueDay} or later within the same week until Friday.
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
    );
};

export default ChildDashboard;