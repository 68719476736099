import React, { useState, useEffect } from 'react';
import FavoriteChoreList from '../components/FavoriteChoreList';
import choreService from '../services/choreService';
import ChoreList from '../components/ChoreList';
import Rewards from '../components/Rewards';
import CHNavbar from '../components/Navbar';
import ChoreForm from '../components/ChoreForm';
import { fetchChores, fetchUsers, mergeUserNamesIntoChores } from '../services/choreService';
import { Container, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AdminDashboard = () => {
    const [chores, setChores] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedChore, setSelectedChore] = useState(null);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [rejectionExplanation, setRejectionExplanation] = useState('');
    // eslint-disable-next-line
    const [error, setError] = useState(null);
    const reviewChores = chores.filter(chore => chore.status === 'in review');
    const [imageLoading, setImageLoading] = useState({});    
    const [showEditImage, setShowEditImage] = useState(true);
    const [favorites, setFavorites] = useState([]);


    useEffect(() => {
        /**
         * Fetches chores and users data concurrently and updates the state.
         * Merges user names into the chores data before setting state.
         * Sets an error message if the fetching process fails.
         */
        const fetchData = async () => {
            try {
                const [chores, users] = await Promise.all([fetchChores(), fetchUsers()]);
                setUsers(users);
                setChores(mergeUserNamesIntoChores(chores, users));
            } catch (err) {
                setError('Failed to fetch data');
            }
        };

        /**
         * Fetches favorite chores from the server and updates the state.
         * If the fetching process fails, sets an error message.
         */
        const fetchFavorites = async () => {
            try {
                const responseFavorites = await choreService.getFavoriteChores();
                setFavorites(responseFavorites.data);
            } catch (err) {
                console.error('Error fetching favorites:', err);
                setError('Failed to fetch favorite chores');
            }
        }

        fetchData();
        fetchFavorites(); 

        const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');

        // Triggered when the WebSocket connection is established.
        // Logs a success message to the console.
        ws.onopen = () => {
            //console.log('Admin WebSocket connection established');
        };
    
        /**
         * Handle WebSocket messages from the server. If the message is a favorite-related
         * event, re-fetch the favorites. If the message is a chore-related event, re-fetch
         * the chores.
         * @param {MessageEvent} event the WebSocket message event
         */
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
    
            const favoriteMessageTypes = [
                'NEW_FAVORITE_ADDED', 
                'FAVORITE_DELETED'
            ];
            
            if (favoriteMessageTypes.includes(message.type)) {
                fetchFavorites();
            }

            const choreMessageTypes = [
                'CHORE_DELETED',
                'NEW_CHORE_ADDED',
                'CHORE_SELECTED',
                'CHORE_REVIEW',
                'CHORE_COMPLETED',
                'CHORE_UPDATED'
            ];

            if (choreMessageTypes.includes(message.type)) {
                fetchData(); // Re-fetch chores when a chore-related event occurs
            }
        };
    
        /**
         * Triggered when the WebSocket connection has an error.
         * Logs the error to the console.
         * @param {ErrorEvent} error the WebSocket error event
         */
        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    
        /**
         * Triggered when the WebSocket connection is closed.
         * Logs a success message to the console.
         */
        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };
    
        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, []);

    /**
     * Handles the review of a chore by setting the selected chore and showing the review modal.
     * @param {Object} chore - The chore to be reviewed.
     */
    const handleReviewChore = (chore) => {
        setSelectedChore(chore);
        setShowReviewModal(true);
    };

    /**
     * Handles the rejection of a chore by validating the rejection explanation
     * and calling `handleReviewAction` with the 'rejected' status.
     */
    const handleRejectChore = () => {
        if (!rejectionExplanation.trim()) {
            setError('Rejection explanation is required.');
            return;
        }
        handleReviewAction('rejected');
    };

    /**
     * Handles the review action for a chore by updating its status and
     * rejection explanation in the database, updating the chores state,
     * and notifying the child dashboard about the completed chore.
     * @param {string} status - The new status of the chore (completed, rejected, or pending)
     */
    const handleReviewAction = async (status) => {
        try {
            await choreService.reviewChore(selectedChore._id, status, rejectionExplanation);
            setChores(chores.map(chore => (chore._id === selectedChore._id ? { ...chore, status, rejectionExplanation } : chore)));
            setShowReviewModal(false);
            setShowRejectionModal(false);
            setSelectedChore(null);
            setRejectionExplanation('');
            fetchChores();
            // Notify the child dashboard about the completed chore
            const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'CHORE_COMPLETED' }));
                ws.close();
            };
        } catch (err) {
            console.error('Error reviewing chore:', err);
            setError('Failed to review chore');
        }
    };
    
    /**
     * Handles a new chore being created by adding it to the list of chores
     * @param {Object} newChore The new chore object
     */
    const handleChoreCreated = (newChore) => {
        setChores([...chores, newChore]);
      };

    /**
     * Fetches the favorite chores from the server and updates the component state.
     * Handles errors by logging them to the console and setting an error message in the component state.
     */
    const fetchFavorites = async () => {
        try {
            const responseFavorites = await choreService.getFavoriteChores();
            setFavorites(responseFavorites.data);
        } catch (err) {
            console.error('Error fetching favorites:', err);
            setError('Failed to fetch favorite chores');
        }
    };

    /**
     * Handles a new chore being submitted by creating or updating it in the server and
     * updating the component state. If a chore is selected, updates the chore. Otherwise,
     * creates a new chore and adds it to the list of chores. Handles errors by logging them
     * to the console and setting an error message in the component state.
     * @param {Object} choreData The new or updated chore data
     */
    const handleChoreSubmitted = async (choreData) => {
        try {
            console.log('Chore data being submitted:', choreData);
            if (selectedChore) {
                await choreService.updateChore(selectedChore._id, choreData);
            } else {
                const newChore = await choreService.createChore(choreData); 
                console.log('New chore created:', newChore.data);
                setChores([...chores, newChore.data].sort((a, b) => b.points - a.points));
            }
            await fetchChores();
            await fetchFavorites();
            setShowEditModal(false);
        } catch (error) {
            console.error('Error submitting chore:', error);
            setError('Failed to submit chore');
        }
    };
    
    /**
     * Handles a chore being deleted by removing it from the component state and
     * deleting it in the server. If the chore is currently being edited, closes the
     * edit modal. Handles errors by logging them to the console.
     * @param {string} id The ID of the chore to be deleted
     */
    const handleChoreDeleted = async (id) => {
        try {
            setChores(chores.filter(chore => chore._id !== id));
            await choreService.deleteChore(id);
            fetchChores();
            fetchFavorites();
        } catch (error) {
            console.error('Error deleting chore:', error);
        }
    };

    /**
     * Handles the add chore button being clicked by setting the selected chore to null,
     * opening the add modal, and fetching the updated list of chores and favorite chores
     * from the server.
     */
    const handleAddChore = () => {
        console.log('handleAddChore called');
        setSelectedChore(null);
        setShowAddModal(true);
        //fetchChores();
        //fetchFavorites();
    };

    /**
     * Handles a chore being edited by setting the selected chore to the
     * given chore and opening the edit modal. Fetches the updated list of
     * chores and favorite chores from the server.
     * @param {Object} chore The chore to be edited
     */
    const handleEditChore = (chore) => {
        setSelectedChore(chore);
        setShowEditModal(true);
        fetchChores();
        fetchFavorites();
    };

    /**
     * Toggles the visibility of the edit image section by updating
     * the state value `showEditImage` to its opposite.
     */
    const handleToggleEditImageClick = () => {
        setShowEditImage(!showEditImage);
    };

    return (
        <Container className="mb-5 a-dash">

            <CHNavbar title="Admin Dashboard" />
            
            <ChoreList
                chores={chores}
                onEditChore={handleEditChore}
                onAddChore={handleAddChore}
                handleReviewChore={handleReviewChore}
                reviewChores={reviewChores}
                isAdmin={true}
            />

            <FavoriteChoreList 
                favorites={favorites} 
                onChoreCreated={handleChoreCreated}  
            />
            
            <Rewards isAdmin={true} />

            <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Review Chore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedChore && (
                        <>
                            <p>{selectedChore.description}</p>
                            {selectedChore.imagePath && (
                                <div className="chore-image-container">
                                    <div
                                        className="chore-image-spinner"
                                        style={{ display: imageLoading ? 'flex' : 'none' }}
                                    >
                                        <Spinner animation="grow" />
                                    </div>
                                    {
                                    selectedChore.imagePath.endsWith('.mp4') ||
                                    selectedChore.imagePath.endsWith('.mov') ||
                                    selectedChore.imagePath.endsWith('.MOV') ? (
                                        <video 
                                            alt="Chore Evidence" 
                                            className="chore-image"
                                            controls
                                            autoPlay
                                            onLoadedData={() => setImageLoading(false)}
                                            onError={() => setImageLoading(false)}
                                        >
                                            <source 
                                                src={selectedChore.imagePath} 
                                                className="chore-image"
                                                type={selectedChore.imagePath.endsWith('.mp4') ? 'video/mp4' : 'video/mp4'}
                                                style={{ display: imageLoading ? 'none' : 'block' }}
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img 
                                            src={selectedChore.imagePath} 
                                            alt="Chore Evidence" 
                                            className="chore-image"
                                            style={{ display: imageLoading ? 'none' : 'block' }}
                                            onLoad={() => setImageLoading(false)}
                                            onError={() => setImageLoading(false)}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReviewModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" onClick={() => handleReviewAction('completed')}>
                        Approve
                    </Button>
                    <Button variant="danger" onClick={() => { setShowReviewModal(false); setShowRejectionModal(true); }}>
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRejectionModal} onHide={() => setShowRejectionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Rejection Explanation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="rejectionExplanation">
                        <Form.Label>Rejection Explanation</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={rejectionExplanation}
                            onChange={(e) => setRejectionExplanation(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowRejectionModal(false); setShowReviewModal(true); }}>
                        Back
                    </Button>
                    <Button variant="danger" onClick={handleRejectChore}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Chore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showEditImage && selectedChore && selectedChore.imagePath && (
                        <div className="chore-image-container mb-3">
                            <div
                                className="chore-image-spinner"
                                style={{ display: imageLoading ? 'flex' : 'none' }}
                            >
                                <Spinner animation="grow" />
                            </div>
                            {selectedChore.imagePath && (
                                selectedChore.imagePath.endsWith('.mp4') || 
                                selectedChore.imagePath.endsWith('.mov') || 
                                selectedChore.imagePath.endsWith('.MOV') ? (
                                    <video
                                        src={selectedChore.imagePath}
                                        alt="Chore Evidence"
                                        className="chore-image"
                                        controls
                                        autoPlay
                                        onLoadedData={() => setImageLoading(false)}
                                        onError={() => setImageLoading(false)}
                                    >
                                        <source
                                            src={selectedChore.imagePath}
                                            type={selectedChore.imagePath.endsWith('.mp4') ? 'video/mp4' : 'video/quicktime'}
                                            className="chore-image"
                                            style={{ display: imageLoading ? 'none' : 'block' }}
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        src={selectedChore.imagePath}
                                        alt="Chore Evidence"
                                        className="chore-image"
                                        style={{ display: imageLoading ? 'none' : 'block' }}
                                        onLoad={() => setImageLoading(false)}
                                        onError={() => setImageLoading(false)}
                                    />
                                )
                            )}
                        </div>
                    )} 
                    {selectedChore && selectedChore.imagePath && (                  
                        <Button className='toggle-edit-image' onClick={handleToggleEditImageClick}>
                            {showEditImage ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    )}
                    <ChoreForm
                        onChoreSubmitted={handleChoreSubmitted}
                        initialChore={selectedChore}
                        users={users}
                        choreStatusOptions={[
                            'new', 
                            'pending', 
                            'in review', 
                            'completed', 
                            'rejected'
                        ]}
                        closeModal={() => setShowEditModal(false)}
                        onChoreDeleted={handleChoreDeleted}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Chore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChoreForm
                        onChoreSubmitted={handleChoreSubmitted}
                        users={users}
                        choreStatusOptions={[
                                'new', 
                                'pending', 
                                'in review', 
                                'completed', 
                                'rejected'
                            ]}
                        closeModal={() => setShowAddModal(false)}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default AdminDashboard;