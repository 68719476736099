import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import { Container, Button, Navbar, Nav } from 'react-bootstrap';
import '../assets/css/Login.css';

const Login = () => {
    const [pin, setPin] = useState('');
    const navigate = useNavigate();

    const authenticate = useCallback(async (pin) => {
        try {
            const data = await authService.login(pin);
            console.log('Login response:', data);
            if (data.token) {
                navigate('/child');
            }
        } catch (err) {
            console.error('Login error:', err.response.data);
            const pinDisplay = document.querySelector('.pin-display');
            pinDisplay.classList.add('shake');
            setTimeout(() => {
                pinDisplay.classList.remove('shake');
                setPin('');
            }, 500);
        }
    }, [navigate]);

    useEffect(() => {
        const verifyUserId = async () => {
            try {
                const data = await authService.verifyUserId();
                if (data.valid) {
                    navigate('/child');
                } else {
                    authService.logout();
                }
            } catch (err) {
                authService.logout();
            }
        };

        verifyUserId();
    }, [navigate]);

    const handleButtonClick = (value, event) => {
        if (pin.length < 4) {
            const newPin = pin + value;
            setPin(newPin);
            if (newPin.length === 4) {
                authenticate(newPin);
            }

            const button = event.target;
            button.classList.add('pressed');
            setTimeout(() => {
                button.classList.remove('pressed');
            }, 250);
        }
    };

    const handleDelete = () => {
        setPin('');
    };

    return (
        <div className="login-page">
            <Container className="login-container">
                <img
                    alt=""
                    src={require('../assets/images/logo-text-174x100.webp')}
                    width="auto"
                    height="100"
                    className="d-inline-block align-top"
                />
                <h2 className='mt-5'>Enter Passcode</h2>
                <div className="pin-display">
                    {[...Array(4)].map((_, i) => (
                        <div key={i} className={`pin-dot ${i < pin.length ? 'filled' : ''}`}></div>
                    ))}
                </div>
                <div className="keypad">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0].map((value, i) => (
                        <Button
                            key={i}
                            variant="light"
                            className={`keypad-button${value === null ? ' invisible' : ''}`}
                            onClick={(event) => value !== null && handleButtonClick(value, event)}
                            aria-label={value !== null ? `Press digit ${value}` : 'Empty'}
                        >
                            {value}
                        </Button>
                    ))}
                    <Button
                        className="invisible-button-clickable"
                        variant="light"
                        onClick={() => navigate('/admin')}                   
                        aria-label="Go to admin page"
                    >
                    </Button>
                </div>
                <Navbar fixed="bottom" className="keypad-footer">
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => navigate('/signup')}>Register</Nav.Link>
                    </Nav>
                    <Nav>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={handleDelete}>Cancel</Nav.Link>
                    </Nav>
                </Navbar>
            </Container>
        </div>
    );
};

export default Login;
